body {
  margin: 0;
  /* font-family:  */
  font-family: 'Euclid Circular B';
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@tailwind base;
@tailwind components;
@tailwind utilities;

.swiper-button-next {
  background: white;
  width: 35px !important;
  height: 35px !important;
  color: black;
  display: flex;
  align-items: center;
  border: 1px solid #7A61B9;
  border-radius: 50%;
  cursor: pointer;
  right: 0;


}

.swiper-button-prev:after, .swiper-rtl .swiper-button-next:after {
  font-size: 14px !important;
}

.swiper-button-prev {
  background: white;
  width: 35px !important;
  height: 35px !important;
  color: black;
  display: flex;
  align-items: center;
  border: 1px solid #7A61B9;
  border-radius: 50%;
  cursor: pointer;
  left: 0;
}

.swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after {
  font-size: 14px !important;

}